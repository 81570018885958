import React from "react"
import Margin from "../components/Layout/Margin/Margin"

import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"

const NotFoundPage = () => (
  <Layout>

    <SEO title="404: Not found!" />

    <Margin>

    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>

    </Margin>

    
    
  </Layout>
)

export default NotFoundPage
