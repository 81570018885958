import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

const Margin = ({ children }) => {
  
  return (
    <>
        <MarginDiv>

            {children}

        </MarginDiv>
      
    </>
  )
} 

Margin.propTypes = {
  children: PropTypes.node.isRequired,
}

const MarginDiv = styled.div `
  padding-top: 40px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 599px) {
    margin-left: 15px;
    margin-right: 15px;
    }

`;

export default Margin
